import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://49b97569ec00931aeba51cbfb3f6092b@o4506999633739776.ingest.us.sentry.io/4506999661592576",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});